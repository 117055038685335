<template>
    <div class="edit-cwo">
        <title-bar
            v-if="cwo"
            :submitBtn="primaryBtn"
            :submitBtnTitle="primaryButtonText"
            :secondaryBtn="secondaryBtn"
            :secondaryBtnTitle="secondaryButtonText"
            :thirdBtn="thirdBtn"
            :thirdBtnTitle="thirdButtonText"
            :fourthBtn="fourthBtn"
            :fourthBtnTitle="fourthButtonText"
            :fifthBtn="fifthBtn"
            :fifthBtnTitle="fifthButtonText"
            :sixthBtn="sixthBtn"
            :sixthBtnTitle="sixthButtonText"
            :edit="editBtn"
            :title="'Update CWO - ' + cwo.referenceNumber"
            @submitPressed="submitPressed"
            @secondaryPressed="secondaryPressed"
            @thirdPressed="thirdPressed"
            @fourthPressed="fourthPressed"
            @fifthPressed="fifthPressed"
            @sixthPressed="sixthPressed"
            @editPressed="edit = !edit"
        />
        <b-card no-body>
            <b-tabs v-model="tabIndex" card content-class="mt-3">
                <b-tab :title="$t('forms.overview')" active>
                    <cwo-form
                        v-if="cwo"
                        :cwoObject="cwo"
                        :action="action"
                        :edit="edit"
                        @clearAction="action = null"
                    />
                </b-tab>
                <b-tab :title="$t('forms.files')">
                    <files-form
                        :resource="$Cwos"
                        :resource-id="$route.params.id"
                        :file-tags="['files']"
                        :can-upload="canUpload()"
                        :message="'cwoUpdated'"
                    />
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import CwoForm from "./components/CwoFormEditE1.vue";
import FilesForm from "@/components/Files/FilesForm.vue";

export default {
    components: {
        TitleBar,
        CwoForm,
        FilesForm
    },
    data() {
        return {
            tabIndex: 0,
            cwo: null,
            action: null,
            customerList: null,
            primaryBtn: true,
            primaryButtonText: this.$t('navigations.confirm'),
            secondaryBtn: false,
            secondaryButtonText: this.$t('navigations.void'),
            thirdBtn: false,
            thirdButtonText: this.$t('navigations.defer'),
            fourthBtn: false,
            fourthButtonText: this.$t('navigations.nccwo'),
            fifthBtn: true,
            fifthButtonText: this.$t('forms.save'),
            sixthBtn: true,
            sixthButtonText: this.$t('navigations.print'),
            editBtn: false,
            edit: false
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$Cwos.getResource({id}).then((response) => {
                this.cwo = response.data;
                this.setButtons()
            });
        },
        setButtons() {
            if (this.cwo.status === "OPEN") {
                if (this.$helper.userAdditionalCapability('cwo_void')) {
                    this.secondaryBtn = true
                }
                this.editBtn = true
            }
            if (this.cwo.status === "CONFIRMED") {
                this.primaryButtonText = "Close"
                if (!this.$helper.userAdditionalCapability('cwo_void')) {
                    this.secondaryBtn = false
                }
                this.thirdBtn = true
                this.fourthBtn = true
                if (!this.$helper.userAdditionalCapability('cwo_close')) {
                    this.primaryBtn = false
                }
                if (!this.$helper.userAdditionalCapability('cwo_deferred')) {
                    this.thirdBtn = false
                }
            }
            if (this.cwo.status === "DEFERRED") {
                this.primaryButtonText = "Close"
                if (this.$helper.userAdditionalCapability('cwo_void')) {
                    this.secondaryBtn = true
                }
                this.thirdBtn = true
                this.fourthBtn = true
                if (!this.$helper.userAdditionalCapability('cwo_close')) {
                    this.primaryBtn = false
                }
                if (!this.$helper.userAdditionalCapability('cwo_deferred')) {
                    this.thirdBtn = false
                }
            }
            if (this.cwo.status === "CLOSED" || this.cwo.status === "VOIDED") {
                this.primaryBtn = false
                this.fifthBtn = false
            }
        },
        submitPressed() {
            if (this.cwo.status === "OPEN") {
                this.action = "CONFIRMED";
            }
            if (this.cwo.status === "CONFIRMED" || this.cwo.status === "DEFERRED") {
                if (window.confirm('Are you sure you want to close this CWO? \n\nDid you check everything and filled all the necessary information? \n\nActions taken \n\nMaterials/tooling used \n\nPart requests \n\nManpower used')) {
                    this.action = "CLOSED";
                }
            }
        },
        secondaryPressed() {
            this.action = "VOIDED"
        },
        thirdPressed() {
            this.action = "DEFERRED"
        },
        fourthPressed() {
            this.action = "NC"
        },
        fifthPressed() {
            this.action = "SAVE"
        },
        sixthPressed() {
            const name = `cwo_${this.cwo.id}`;
            const body = {
                name,
                resource: this.cwo["@id"],
                templateName: "CWO",
                regenerate: true
            };
            this.$Pdfs.createResource({body}).then((response) => {
                if (response.status === 201) {
                    const link = document.createElement("a");
                    link.href = response.data.url;
                    link.target = "_blank";
                    link.click();
                }
            });
        },
        canUpload() {
            if(this.$helper.userAdditionalRole(process.env.VUE_APP_ROLE_ENGINEER)) {
                if(this.cwo && this.cwo.status === "CLOSED") {
                    return true
                }
                return false
            }
            if(this.$helper.getUserMainRole() === 'ROLE_CUSTOMER') {
                if(this.cwo && this.cwo.status === "OPEN") {
                    return true
                }
                return false
            }
            return true
        }
    },
};
</script>
